*, :after, :before {
  box-sizing: border-box;
}

body {
  font-family: Roboto, sans-serif;
}

.header {
  width: 100%;
  color: #fff;
  padding: 0 20px;
  position: absolute;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .header {
    padding: 0 50px;
  }
}

.hero {
  height: 100vh;
  color: #fffeff;
  background-color: #0000;
  background-image: linear-gradient(#0000004d, #0000004d), url("assets/images/apartment.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  justify-content: center;
  padding: 100px 50px 150px 100px;
  display: flex;
}

.hero__textContainer {
  margin-left: 20px;
  font-size: 58px;
}

.hero__text {
  margin: 25px 0;
  font-weight: 500;
}

.hero__text:last-child {
  word-wrap: all;
}

.hero__information {
  z-index: 5;
  max-width: 750px;
  background-color: #5fffb5;
  place-items: center;
  padding: 20px;
  display: grid;
  position: relative;
}

.hero__information p {
  color: #364558;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.hero__form {
  z-index: -1;
  width: 90%;
  -webkit-box-shadow: inset 0 10px 15px -10px #000;
  -moz-box-shadow: inset 0 10px 15px -10px #000;
  background-color: #00cc87;
  padding: 10px;
  position: absolute;
  top: 100%;
  left: 10%;
  box-shadow: inset 0 10px 15px -13px #000;
}

.hero__form form {
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.hero__form input {
  color: #364558;
  background-color: #0000;
  border: none;
  flex: 1;
  padding: 10px 15px;
  display: inline-block;
}

.hero__form svg {
  height: 20px;
  width: 20px;
  margin-left: 15px;
  display: inline-block;
}

.hero__form input::placeholder {
  color: #364558;
}

@media (max-width: 1230px) {
  .hero__textContainer {
    font-size: 48px;
  }

  .hero__information p {
    font-size: 16px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .hero {
    padding: 100px 50px 150px 80px;
  }

  .hero__information {
    padding: 0 20px;
  }

  .hero__information p {
    font-size: 14px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .hero {
    padding: 100px 20px 150px;
  }

  .hero__textContainer {
    font-size: 34px;
  }

  .hero__text {
    margin: 10px 0;
    font-weight: 500;
  }

  .hero__information {
    padding: 0 20px;
  }

  .hero__information p {
    font-size: 14px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .hero {
    padding: 100px 80px 150px;
  }

  .hero__textContainer {
    font-size: 32px;
  }

  .hero__text {
    margin: 10px 0;
    font-weight: 500;
  }

  .hero__information {
    padding: 0 20px;
  }

  .hero__information p {
    font-size: 14px;
  }
}

.about {
  max-width: 1980px;
  margin: 0 auto;
  padding: 200px 20px 20px;
  position: relative;
  overflow: hidden;
}

.about__guestContainer {
  text-align: center;
  position: absolute;
  top: 50px;
  right: 150px;
}

.about__guestContainer .svgContainer {
  width: 500px;
  height: 300px;
  position: relative;
}

.about__guestContainer .svgContainer:after {
  content: url("ellipse.8229318e.png");
  z-index: -1;
  opacity: .6;
  position: absolute;
  top: -100px;
  left: 0;
}

.about__guestContainer .svgContainer svg {
  width: 100%;
  height: 100%;
}

.about__guestText {
  max-width: 350px;
  color: #364558;
  margin: 35px auto 0;
  font-size: 17px;
  font-weight: 500;
}

.about__inner {
  padding-top: 30%;
}

.about__header {
  color: #364558;
  margin: 0 0 0 20px;
  font-size: 32px;
  font-weight: 400;
  position: relative;
}

.about__header:after {
  content: url("smalEllipse.946fcfd9.png");
  z-index: -1;
  opacity: .7;
  position: absolute;
  top: -70px;
  left: -10px;
  transform: rotate(-75deg);
}

.about__flexContainer {
  color: #364558;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 80px 0 0;
  display: flex;
}

.about .card {
  max-width: 360px;
  flex-direction: column;
  margin: 0 100px;
  display: flex;
}

.about .card h3, .about .card p {
  margin: 0;
}

.about .card__icon {
  width: 30px;
  height: 30px;
  margin-bottom: 25px;
}

.about .card__icon svg {
  width: 100%;
  height: 100%;
}

.about .card__title {
  font-size: 21px;
}

.about .card__content {
  text-align: left;
  max-width: 300px;
  margin-top: 35px;
  font-size: 19px;
  font-weight: 400;
  line-height: 25px;
}

@media (min-width: 1600px) {
  .about__guestContainer .svgContainer {
    width: 700px;
    height: 400px;
    position: relative;
  }

  .about__guestText {
    max-width: 370px;
    font-size: 19px;
  }

  .about__flexContainer {
    padding: 80px 0 10px;
  }

  .about__inner {
    padding-top: 25%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .about__guestContainer {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: auto;
    right: auto;
  }

  .about__guestContainer .svgContainer {
    width: 420px;
  }

  .about__inner {
    padding-top: 13%;
  }

  .about .card {
    margin: 30px 40px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .about {
    padding: 140px 20px 10px;
  }

  .about__guestContainer {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: auto;
    right: auto;
  }

  .about__guestContainer .svgContainer {
    width: 360px;
  }

  .about__inner {
    padding-top: 15%;
  }

  .about .card {
    margin: 35px 40px 35px 42px;
  }

  .about .card:first-child {
    margin-top: 10px;
  }

  .about .card:last-child {
    margin: 35px 40px 45px;
  }

  .about__header:after {
    top: -30px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .about {
    padding: 140px 20px 0;
  }

  .about__guestContainer {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    top: auto;
    right: auto;
  }

  .about__guestContainer .svgContainer {
    width: 400px;
  }

  .about__inner {
    padding: 10% 40px 0;
  }

  .about .card:last-child {
    margin: 40px 34px 0;
  }

  .about__flexContainer {
    padding: 80px 0;
  }
}

@media (min-width: 481px) and (max-width: 967px) and (orientation: landscape) {
  .about__flexContainer {
    padding: 60px 0;
  }

  .about .card {
    margin: 40px;
  }
}

.services {
  max-width: 1980px;
  background-color: #f1eded2f;
  margin: 0 auto;
  padding: 80px 20px 40px;
  overflow: hidden;
}

.services__title {
  color: #364558;
  margin: 0 0 0 20px;
  font-size: 32px;
  font-weight: 400;
  position: relative;
}

.services__title:after {
  content: url("smalEllipse.946fcfd9.png");
  z-index: -1;
  opacity: .7;
  position: absolute;
  top: -70px;
  left: -10px;
  transform: rotate(-25deg);
}

.services__container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  display: flex;
}

.service {
  height: 240px;
  max-width: 470px;
  color: #364558;
  text-align: center;
  z-index: 10;
  background-color: #fffeff;
  border-radius: 9px;
  margin: 20px;
  padding: 45px 60px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.service__title {
  margin: 0;
  font-size: 24px;
}

.service__content {
  margin: 25px 0 5px;
  font-weight: 500;
}

.service__svg {
  width: 200px;
  height: 200px;
  opacity: .3;
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
}

.service__svg svg {
  width: 100%;
  height: 100%;
}

.service__svg--chat {
  opacity: 1;
  bottom: -25px;
  left: 20px;
}

.service__svg--price {
  opacity: .2;
  left: 10px;
}

.service__svg--clean {
  left: -40px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .service {
    padding: 25px;
  }

  .service__svg--chat {
    opacity: .5;
  }

  .service:nth-child(2) {
    margin-bottom: 0;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .services {
    padding: 80px 50px 40px;
  }
}

@media (min-width: 481px) and (max-width: 967px) and (orientation: landscape) {
  .service:nth-child(2) {
    margin-bottom: 0;
  }
}

.footer {
  max-width: 1980px;
  color: #364558;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 40px 50px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.footer:after {
  content: url("ellipse.8229318e.png");
  position: absolute;
  top: 0;
  left: -300px;
  transform: rotate(96deg);
}

.footer .companyInformation h3 {
  letter-spacing: 1px;
  font-weight: 500;
}

.footer .companyInformation__addressTitle {
  color: #5fffb5;
  margin: 4px 0;
}

.footer .companyInformation__data {
  margin: 4px 0;
  font-weight: 500;
}

.footer .companyInformation__address {
  margin-bottom: 10px;
}

.footer .companyInformation__address > p {
  margin: 0 0 5px;
  font-weight: 500;
}

.footer .mailCTA {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer .mailCTA h3 {
  margin: 0;
  font-weight: 500;
}

.footer .mailCTA__svg, .footer .mailCTA__svg svg {
  width: 200px;
  height: 200px;
}

.footer .mailCTA__mailLink {
  color: inherit;
  background-color: #e2fcf9;
  border: none;
  border-radius: 8px;
  padding: 15px 30px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.footer .mailCTA__mailLink:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 3px #0000001c, 0 2px 4px #00000021;
}

@media (min-width: 320px) and (max-width: 480px) {
  .footer {
    justify-content: center;
    font-size: 21px;
  }

  .footer:after {
    content: "";
  }

  .footer .mailCTA {
    margin-top: 40px;
  }

  .footer .mailCTA h3 {
    display: none;
  }
}

/*# sourceMappingURL=index.2c7b89ad.css.map */
